import Card from './Component/Card';
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Notiflix from 'notiflix';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbarExport } from '@mui/x-data-grid';
import axios from 'axios';
import { baseUrl } from './Constant';
import moment from 'moment';

export default function Withdrawals() {
  let token = localStorage.getItem('token');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [data, setData] = useState('')
  const [number, setNumber] = useState('')
  const [network, setNetwork] = useState('')
  const [withDrawCount, setWithDrawCount] = useState('')
  const [pagination, setPagination] = useState({ page: 1, pageSize: 100 });
  const [tcount, setTcount] = useState('')
  // Convert the string to a Date object
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);


  let startYear = startDateObj.getFullYear();
  let startMonth = ('0' + (startDateObj.getMonth() + 1)).slice(-2);
  let startDates = ('0' + startDateObj.getDate()).slice(-2);

  let endYear = endDateObj.getFullYear();
  let endMonth = ('0' + (endDateObj.getMonth() + 1)).slice(-2);
  let endDates = ('0' + endDateObj.getDate()).slice(-2);

  let startFormattedDate = `${startYear}-${startMonth}-${startDates}`;
  let endFormattedDate = `${endYear}-${endMonth}-${endDates}`;


  const handleNetworkChange = (event) => {
    setNetwork(event.target.value);
  }

  useEffect(() => {
    const fetchData = async () => {
      Notiflix.Loading.pulse()
      try {
        let apiUrl = `${baseUrl}/user/withdrawals-status?`;

        if (startDate && endDate) {
          apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
        }
        apiUrl += `&mobile=${number}`;
        if (network !== 'all') {
          apiUrl += `&network=${network}`;
        }
        apiUrl += `&page=${pagination.page}&limit=${pagination.pageSize}`;
        const response = await axios.get(
          apiUrl,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("response my", response)
        const modifiedData = response.data.data.rows.map((item, i) => {
          return {
            ...item,
            created_at_date: moment(item.created_at && item.created_at.split('T')[0]).format('DD/MM/YYYY'),
            created_at_time: item.created_at && item.created_at.split('T')[1].split('.')[0],
            mobile: item.userDetail.mobile,
            id: ((pagination.page - 1) * pagination.pageSize) + 1 + i,
            wallet_reward: item.userDetail.wallet_reward?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            status: item.status == 0 ? "Failed" : "Success"

          };
        });
        const total_withdraw_amount = response.data.total_withdraw_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        setTcount(response.data.data.count)
        setWithDrawCount(total_withdraw_amount)
        setData(modifiedData);
        Notiflix.Loading.remove();
      } catch (error) {
        console.log(error, "api does not work");
        Notiflix.Loading.remove();
        Notiflix.Notify.failure(error.message);
      }
    };

    fetchData();
  }, [startDate, endDate, number, network, pagination]);


  const handleDownload = async () => {
    try {
      let apiUrl = `${baseUrl}/user/export-file-data/withdrawal-status?`;

      if (startDate && endDate) {
        apiUrl += `start_date=${startFormattedDate}&end_date=${endFormattedDate}`;
      }

      if (network !== 'all') {
        apiUrl += `&network=${network}`;
      }
      apiUrl += `&mobile=${number}`;
      const response = await axios.get(apiUrl, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Withdrawal Status ${moment().format('DD-MM-YYYY')} .csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.log(error, "API does not work");
      Notiflix.Notify.failure(error.message);
    }
  }

  function CustomToolbar() {
    return (
      <button onClick={handleDownload} className='btn btn-dark'>Export</button>
    );
  }

  const columns = [
    {
      field: 'id',
      headerName: 'SN',
      width: 80,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'mobile',
      headerName: 'Mobile Number',
      width: 120,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },
    {
      field: 'bank_name',
      headerName: 'Bank Name',
      width: 180,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },
    {
      field: 'account_number',
      headerName: 'Bank Number',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },
    {
      field: 'withdraw_amount',
      headerName: 'Withdrawal Amount',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },
    {
      field: 'wallet_reward',
      headerName: 'Balance in Wallet',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },

    // {
    //   field: 'active',
    //   headerName: 'Account Active Status',
    //   width: 150,
    //   headerClassName: 'vertical-line',
    //   cellClassName: 'vertical-line',
    //   align: 'center',
    //   headerAlign: 'center'

    // },

    {
      field: 'created_at_date',
      headerName: 'Withdraw Request Date',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'

    },

    {
      field: 'created_at_time',
      headerName: 'Withdraw Request Time',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },

    {
      field: 'status',
      headerName: 'Withdraw Request Status',
      width: 150,
      headerClassName: 'vertical-line',
      cellClassName: 'vertical-line',
      align: 'center',
      headerAlign: 'center'
    },



  ];

  return (
    <div className='myContainer'>
      <div className='innerContainer'>
        <div className='containerCard'>
          <div className="col-md-3">
            <Card
              title={"Withdrawal Concluded"}
              number={`N ${withDrawCount}`}
              className='myCard indiviusalCard'
            />
          </div>
        </div>

        <div className='row'>
          <div className='filter'>
            <div className='filterByDate date_number'>
              <div>
                <div className='title'>
                  <h6>
                    Filter By Date:
                  </h6>
                </div>
                <div className='datePicker'>
                  <div>
                    <p className='m-0'>Start Date</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                    />
                  </div>
                  <div>
                    <p className='m-0'>End Date</p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat='dd/MM/yyyy'
                      maxDate={new Date()}
                      minDate={startDate}
                    />
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column justify-content-between'>
                <div className='title'>
                  <h6>
                    Filter By Phone Number:
                  </h6>
                </div>
                <div className='mobilePicker'>

                  <div className="input-group">
                    <input type="number" className="form-control" placeholder="mobile number" aria-label="Username" onChange={(e) => setNumber(e.target.value)} />

                  </div>
                </div>
              </div>
            </div>
            <div className='filterByNetwork py-3 d-flex justify-content-between flex-wrap'>
              <div>
                <div className='title'>
                  <h6>Filter By Network:</h6>
                </div>
                <div className='networkPicker'>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="allNetwork" value="all" onChange={handleNetworkChange} checked={network === 'all'} />
                    <label className="form-check-label" htmlFor="allNetwork">All</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="mtnNetwork" value="mtn" onChange={handleNetworkChange} checked={network === 'mtn'} />
                    <label className="form-check-label" htmlFor="mtnNetwork">MTN</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="airtelNetwork" value="airtel" onChange={handleNetworkChange} checked={network === 'airtel'} />
                    <label className="form-check-label" htmlFor="airtelNetwork">Airtel</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="gloNetwork" value="glo" onChange={handleNetworkChange} checked={network === 'glo'} />
                    <label className="form-check-label" htmlFor="gloNetwork">Glo</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="network" id="etisalatNetwork" value="9mobile" onChange={handleNetworkChange} checked={network === '9mobile'} />
                    <label className="form-check-label" htmlFor="etisalatNetwork">9mobile</label>
                  </div>
                </div>
              </div>
              <div className=''>
                <div className='title'>
                  <h6>Filter By Page No:</h6>
                </div>
                <div>
                  <div>
                  <input type="text" className="form-control" placeholder="Page Number"
                   onChange={(e) => {
                    const page = e.target.value ? parseInt(e.target.value, 10) : 1;
                    setPagination((prev) => ({ ...prev, page }));
                  }}
                  />
                  
                  </div>
                </div>
              </div>
              
            </div>

          </div>


        </div>

        <div className="datagrid containerDataGrid">
          <h5>Withdrawals Status</h5>

          <div className="datagridBox">
            <Box sx={{ height: '500px', width: '100%' }}>
              <DataGrid
                rows={data}
                columns={columns}
                paginationModel={{
                  page: pagination.page - 1,
                  pageSize: pagination.pageSize,
                }}

                paginationMode='server'
                pageSize={pagination.pageSize}
                rowCount={tcount}
                pageSizeOptions={[10]}
                rowsPerPageOptions={[10]}
                onPaginationModelChange={(e, f) => { setPagination({ ...pagination, page: e.page + 1, pageSize: e.pageSize }) }}
                slots={{
                  toolbar: CustomToolbar,
                }}

              />
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}




